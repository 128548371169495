<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h3 text-typo font-weight-bold mb-2">
          Сургуулийн сорил
          <span v-if="assignmentsOfSchool"
            >({{ assignmentsOfSchool.length }})</span
          >
        </h5>
        <p class="text-body">Сургуулиас зоxион байгуулсан сорилууд</p>
        <!-- <v-img src="@/assets/img/teachersorillogo.jpg"   /> -->
      </v-col>
    </v-row>
    <section
      v-if="assignmentsOfSchool && assignmentsOfSchool.length > 0"
      class="mt-4"
    >
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="assignment in assignmentsOfSchool"
          :key="assignment.id"
        >
          <v-card class="card-shadow border-radius-xl" :ripple="false">
            <div class="px-4 py-4">
              <div class="d-flex">
                <div class="my-auto">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ assignment.name }}
                    <!-- <small
                      v-if="assignment.assignmentType == 2"
                      style="color: red"
                      >, ЭЕШ-шалгалт</small
                    >
                    <small
                      v-else
                      style="color: red; font-weight: normal !important"
                      >, Стандарт шалгалт</small
                    > -->
                  </h6>
                  <small v-if="assignment.selectedLessonCategory">{{
                    assignment.selectedLessonCategory.name
                  }}</small>

                  <p v-if="assignment.examVariant">
                    <span class="red--text">Xувилбар</span>:
                    <span>{{ assignment.examVariant }}</span>
                  </p>
                  <span v-if="assignment.courseInfo" class="blue--text">{{
                    assignment.courseInfo.COURSE_NAME
                  }}</span>
                  <p v-if="assignment.examScope">
                    <small>
                      {{ assignment.examScope.name }}
                      <span v-if="assignment.schoolIndex"
                        >, {{ assignment.schoolIndex }}</span
                      ></small
                    >
                  </p>
                  <small
                    style="display: block"
                    v-if="assignment.createdByFirstName"
                  >
                    {{ assignment.createdByFirstName }}
                    <span
                      class="font-weight-bold"
                      v-if="
                        assignment.createdBySchoolName &&
                        assignment.examScope.sorilIndex == 1
                      "
                      >,
                      {{ assignment.createdBySchoolName }}
                    </span></small
                  >
                </div>
              </div>
              <v-card-actions class="d-flex flex-row justify-end px-0 pb-4">
                <h1 class="mr-1">{{ assignment.duration }}</h1>

                <span v-if="assignment.duration">минут</span>
                <span
                  v-else
                  class="text-alert font-weight-bold"
                  style="color: red"
                  >Xугацаа оруулаагүй</span
                >
                <v-spacer></v-spacer>
                <h1
                  class="mr-1"
                  v-if="assignment.finalScore"
                  style="color: red"
                >
                  {{ assignment.finalScore }}
                </h1>
                <span v-if="assignment.finalScore">оноотой</span>
              </v-card-actions>
              <p class="text-sm mt-4 text-body">{{ assignment.description }}</p>
              <hr class="horizontal dark my-4" />

              <v-card-actions class="d-flex flex-row justify-end px-0 pb-4">
                <h1
                  class="mr-1 green--text"
                  v-if="assignment.results && assignment.results.totalScore"
                >
                  {{ assignment.results.totalScore }}
                </h1>

                <span
                  class="green--text"
                  v-if="assignment.results && assignment.results.totalScore"
                  >оноо авсан</span
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  v-if="assignment.results && assignment.results.examTaken"
                  text
                  @click="_showResult(assignment, false)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                  elevation="0"
                  :ripple="false"
                  >Хариулт харах</v-btn
                >
                <v-btn
                  v-else
                  @click="showWarningAlert(assignment)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  >Сорил өгөx</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <h2 class="red--text">Сургуулийн нээлттэй сорил байxгүй байна!</h2>
    </section>
    
    <v-dialog
      v-if="selectedAssignment && openSorilResults"
      v-model="openSorilResults"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h2 class="mx-4 pt-0">
                Сорил:
                {{ selectedAssignment.name }}
                <span v-if="userData.role == 'granditxkk@gmail.com'">{{
                  selectedAssignment.ref.path
                }}</span>
                /<span class="blue--text">{{
                  this.selectedAssignment.finalScore
                }}</span>
                оноо/
              </h2>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="openSorilResults = !openSorilResults"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="selectedAssignment.assignmentType == 1">
            <XShalgaltResults :item="selectedAssignment"></XShalgaltResults>
          </div>
          <div v-else>
            <XShowAssignmentAllResults
              :sorilId="selectedAssignment.ref.path"
              :maxNumber="selectedAssignment.yyy"
              :fromEYESH="true"
            ></XShowAssignmentAllResults>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="openSorilResults = !openSorilResults"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import XShowAssignmentAllResults from "@/views/Pages/Assignments/XShowAssignmentAllResults.vue";
export default {
  components: {
    XShowAssignmentAllResults,
  },
  data: () => ({
    selectedAssignment: null,
    openSorilResults: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        width: "200px",
      },

      // {
      //   text: "Огноо",
      //   align: "start",
      //   sortable: true,
      //   value: "createdAt",
      // },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "selectedLessonCategory.name",
      },
      {
        text: "Xичээлийн код",
        align: "start",
        sortable: true,
        value: "yeshLessonCode",
      },

      {
        text: "Xугацаа",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Оноо",
        align: "start",
        sortable: true,
        value: "finalScore",
      },
    ],
    assignmentsOfSchool: null,
    sharedSorils: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._setupp();
  },
  methods: {
    _detail(item) {
      console.log(item.assignmentType);
      this.selectedAssignment = item;
      if (item.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltEditing",
          params: {
            xpath: item.ref.path,
          },
        });
      } else if (item.assignmentType == 2) {
        if (
          this.userData.role == "superadmin2" ||
          this.userData.email == "granditxxk@gmail.com2"
        ) {
          this.$router.push({
            name: "XShalgaltEditingEyesh",
            params: {
              xpath: item.ref.path,
              fromEYESH: true,
            },
          });
        } else {
          this.showAssignmentInDialog = true;
        }
      }
    },
    _thisOpened(item) {
      this._takeAssignment(item, false);
    },
    _openToSchool(assignment, school, dataa, docId) {
      assignment.ref
        .collection("schools")
        .doc(school.id)
        .set(dataa, { merge: true })
        .then(() => {
          assignment.ref
            .collection("schools")
            .doc(school.id)
            .collection("history")
            .doc(docId)
            .set(dataa, { merge: true });
        });
    },
    _takeAssignment(assignment, open) {
      console.log(open);
      console.log(assignment,"schoolStatus",assignment.ref.path)
      this.$swal({
        title: open
          ? assignment.name + " - Шалгалтыг нээлттэй болгох уу?"
          : assignment.name + " - Шалгалтыг xаалтай болгох уу?",
        text: open
          ? "Энэхүү шалгалтыг нээснээр танай сургуулийн 12-р ангийн сурагчдад харагдана."
          : "Энэхүү шалгалт 12-р ангийн сурагчдад харагдахгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var oDate = new Date();
          let docId =
            oDate.getFullYear() +
            "-" +
            oDate.getMonth() +
            "-" +
            oDate.getDate() +
            "-" +
            oDate.getHours() +
            "-" +
            oDate.getMinutes() +
            "-" +
            oDate.getSeconds();
          //this._openToSchool(assignment, this.userData.school, dataa, docId);
          console.log(this.userData.cityCode);
          if (this.userData.cityCode) {
            fb.db
              .collection("schools")
              .where("deleted", "==", false)
              .where("cityCode", "==", this.userData.cityCode)
              .orderBy("name", "asc")
              .onSnapshot((docs) => {
                var batch = fb.dbeyesh800.batch();
                docs.forEach((doc) => {
                  let ss = doc.data();
                  ss.id = doc.id;
                  ss.ref = doc.ref;

                  var schoolData = {
                    openedAt: oDate,
                    openedByRefPath: this.userData.ref.path,
                    openedByName: this.userData.firstName
                      ? this.userData.firstName
                      : this.userData.email,
                    openedBySchoolName: ss.name,
                    schoolId: ss.id,
                    open: open,
                    openedByCity: true,
                  };
                  var docRef1 = assignment.ref.collection("schools").doc(ss.id);
                  batch.set(docRef1, schoolData, { merge: true });

                  var docRef2 = docRef1.collection("history").doc(docId);
                  batch.set(docRef2, schoolData, { merge: true });
                });
                console.log(this.userData.ref.path);
                batch.commit().then(() => {
                  this.userData.ref
                    .collection("eyesh800")
                    .doc(assignment.id)
                    .set(
                      {
                        openedDate: oDate,
                        openedByRefPath: this.userData.ref.path,
                        openedByName: this.userData.firstName
                          ? this.userData.firstName
                          : this.userData.email,
                        assignmentRefPath: assignment.ref.path,
                        source: "eyesh800",
                        open: open,
                      },
                      { merge: true }
                    );
                  if (open)
                    this.$swal.fire("Сургуулиуд дээр шалгалт нээгдсэн!");
                  else this.$swal.fire("Сургуулиуд дээр шалгалт xаагдсан!");
                });
              });
          } else {
            var dataa = {
              openedAt: oDate,
              openedByRefPath: this.userData.ref.path,
              openedByName: this.userData.firstName
                ? this.userData.firstName
                : this.userData.email,
              openedBySchoolName: this.userData.school.name,
              schoolId: this.userData.school.id,
              open: open,
            };
            // this._openToSchool(assignment, this.userData.school, dataa, docId);

            var qqqRef = assignment.ref
              .collection("schools")
              .doc(this.userData.school.id)

              qqqRef.set(dataa, { merge: true })
              .then(() => {
                assignment.ref
                  .collection("schools")
                  .doc(this.userData.school.id)
                  .collection("history")
                  .doc(docId)
                  .set(dataa, { merge: true }).then(()=>{
                    console.log("dataa", dataa);
                    console.log("qqqRef", qqqRef)
                  })
              });
          }
        }
      });
    },
    _showResult(assignment) {
      console.log(assignment.results.ref.path);
      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: assignment.results.ref.path,
          fromEYESH: false,
        },
      });
    },
    showWarningAlert(assignment) {
      console.log(assignment);
      this.$swal({
        title: assignment.name + " сорилд оролцоxод бэлэн үү?",
        text:
          assignment.name +
          ", " +
          assignment.finalScore +
          ", оноотой!" +
          " <Тийм>  гэсэн товч дарсанаар сорил эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        // footer: '<a href="">Why do I have this issue?</a>',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },

    _takeExam(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          fromEYESH: false,
          backRouterPath: this.$route.path,
        },
      });
    },
    async _setupp() {
      console.log(this.userData.school);
      fb.db
        .collection(
          "schools/" + this.userData.school.id + "/assignmentsdatabase"
        )
        .where("public", "==", true)
        .where("examScope.sorilIndex", "==", 2)
        .where("userTypeId.userTypeId", "==", 2)
        .where("deleted", "==", false)
        .orderBy("createdAt", "desc")
        .get()
        .then((docs) => {
          this.assignmentsOfSchool = [];
          docs.forEach(async (doc) => {
            let soril = doc.data();
            soril.id = doc.id;
            soril.ref = doc.ref;
            await soril.ref
              .collection("results")
              .doc(this.userData.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let results = doc.data();
                  results.ref = doc.ref;
                  results.id = doc.id;
                  results.examTaken = true;
                  soril.results = results;
                  soril.done = true;
                } else {
                  let results = {};
                  results.examTaken = false;
                  soril.results = results;
                }
              });
            if (soril.done || soril.public)
              this.assignmentsOfSchool.push(soril);
          });
        });
      console.log("this.userData.ref.path");
      var query = fb.dbeyesh800.collection("_public-assignments-allschools");
      // .where("teacherRefs", "array-contains", this.userData.ref.path);
      query.orderBy("yeshLessonCode", "asc").onSnapshot((docs) => {
        this.sharedSorils = [];
        var counter = 0;
        docs.forEach((doc) => {
          let ss = doc.data();
          ss.id = doc.id;
          ss.ref = doc.ref;
          counter++;
          ss.counter = counter;
          this.sharedSorils.push(ss);
        });
      });
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("teacherRefs", "array-contains", this.userData.ref)
        .where("courseInfo.COURSE_CLASSIFICATION", "==", "2")
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            console.log(doc.data());
          });
        });
    },
  },
};
</script>
